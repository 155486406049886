import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import VueLogger from 'vuejs3-logger'
import vue3videoPlay from "vue3-video-play"
import "vue3-video-play/dist/style.css"

// import { createAuth0 } from '@auth0/auth0-vue'

const isProd = process.env.VUE_APP_ORIGIN?.startsWith('https://')

const loggerOpts = {
  isEnabled: true,
  logLevel : isProd ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
}

const v3VideoLangEnglish = {
  dashboard: {
    btn: {
      play: 'Play',
      pause: 'Pause',
      fullscreen: 'Fullscreen',
      exitFullscreen: 'Exit Fullscreen',
      mute: 'Mute',
      unmute: 'Unmute',
      back: 'Back',
      pip: 'Picture-in-picture',
    },
    settings: {
      autoplay: 'Autoplay',
      loop: 'Loop',
      speed: 'Speed',
      resolution: 'Resolution'
    },
  },
  layers: {
    error: {
      title: 'Error!',
      2404: 'Video Source Undefined',
      2502: 'Media Network Error',
      2503: 'Video Cannot DECODE',
      2504: 'Video Cannot Play!',
      601: 'Live video Cannot Play!',
    },
    loading: {
      msg: 'Loading ...',
    },
  },
}

loadFonts()

createApp(App)
  .use(vuetify)
  .use(createMetaManager())
  .use(metaPlugin)
  .use(mdiVue, {
    icons: mdijs
  })
  .use(VueLogger, loggerOpts)
  // .use(
  //   createAuth0({
  //     domain: "",
  //     client_id: "",
  //     redirect_uri: window.location.origin,
  //     audience: 'hasura'
  //   })
  // )
  .use(vue3videoPlay, {
    lang: v3VideoLangEnglish
  })
  .mount('#app')
