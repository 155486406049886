<template>
  <v-app :theme="currentTheme" :style="{ 'height': windowHeight + 'px' }">
    <v-container :style="{ 'padding-top': (mdAndUp ? 16 : 0), 'padding-bottom': (mdAndUp ? 16 : 0) }">
      <v-row>
          <v-col cols="12">
            <ul id="Frames">
              <li class="Frame">
                <vue3VideoPlay
                    v-if="streamIsLive && !isIOS"
                    :width="`${Math.min(windowWidth * 0.75, 600)}px`"
                    title="X-mas Tree"
                    :src="streamOptions.src"
                    :type="streamOptions.type"
                    :autoPlay="true"
                    :muted="true"
                    :controlBtns="['volume','fullScreen']"
                    :cover="require('@/assets/vecteezy_hand-drawn-christmas-tree_9346313.png')"
                  />
                  <video v-if="streamIsLive && isIOS" controls playsinline muted autoplay disablePictureInPicture height="450" :width="`${Math.min(windowWidth * 0.75, 600)}`"
                  :poster="require('@/assets/vecteezy_hand-drawn-christmas-tree_9346313.png')"
                  >
                    <source
                      :src="streamOptions.src"
                      type="application/x-mpegURL">
                  </video>
                  <v-img
                    v-if="!streamIsLive"
                    :aspect-ratio="1.0"
                    :width="`${Math.min(windowWidth * 0.5, 400)}px`"
                    :src="require('@/assets/vecteezy_hand-drawn-christmas-tree_9346313.png')"
                  ></v-img>
              </li>
            </ul>
          </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="christmas-text">
            <p :style="{ 'font-size': (mdAndUp ? '70px' : '50px') }">Merry Christmas!</p>
            <p :style="{ 'font-size': (mdAndUp ? '40px' : '30px') }">- Mike & Muska 🎄❤️</p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-center align-center flex-column flex-sm-row fill-height">
            <v-btn size="x-small" variant="plain" disabled>
              v{{ projectVersion }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { onBeforeUnmount, onMounted, inject, reactive, ref, watch } from 'vue'
// import { useAuth0 } from '@auth0/auth0-vue'
import { useDisplay } from 'vuetify'
import { checkRTMPStreamURL } from './utils/rtmpUtils.js'

export default {
  name: 'App',
  metaInfo() {
    return {
    // // Children can override the title.
    // title: `Vue-SPA-Template v${process.env.PACKAGE_VERSION}`,
    // // Result: My Page Title ← My Site
    // // If a child changes the title to "My Other Page Title",
    // // it will become: My Other Page Title ← My Site
    // // titleTemplate: '%s ← My Site',
    // // Define meta tags here.
    // meta: [
    //   {name: 'description', content: 'Description of SPA.'},
    //   // OpenGraph data (Most widely used)
    //   {property: 'og:title', content: 'Description of SPA.'},
    //   {property: 'og:site_name', content: 'Description of SPA.'},
    //   // The list of types is available here: http://ogp.me/#types
    //   {property: 'og:type', content: 'website'},
    //   // Should the the same as your canonical link, see below.
    //   {property: 'og:url', content: 'https://www.prod-spa-site.com/'},
    //   {property: 'og:image', content: 'https://www.prod-spa-site.com/favicon.png'},
    //   // Often the same as your meta description, but not always.
    //   {property: 'og:description', content: 'Description of SPA'},
    //   // Google / Schema.org markup:
    //   {itemprop: 'name', content: `Vue-SPA-Template v${process.env.PACKAGE_VERSION}`},
    //   {itemprop: 'description', content: 'Description of SPA.'},
    //   {itemprop: 'image', content: 'https://www.prod-spa-site.com/favicon.png'}
    // ],
    // link: [
    //   {rel: 'canonical', href: 'https://www.prod-spa-site.com/'}
    // ]
  }
  },
  components: {
  },
  setup() {
    // Logger
    const logger = inject('vuejs3-logger')

    // App Constants
    const componentTitle = ref(`Mike and Muska's Christmas Tree 2023 🎄❤️`)
    const projectVersion = ref(process.env.PACKAGE_VERSION || '0')

    // Live stream video config
    const streamOptions = reactive({
      src: "https://xmas.mjchp.com/live/xmastree/index.m3u8",
      type: "m3u8",
    });

    // Window Size Mgmt
    const { mdAndUp } = useDisplay()
    const windowHeight = ref(window.innerHeight)
    const windowWidth = ref(window.innerWidth)

    watch(windowHeight, (newHeight, oldHeight) => {
      logger.debug(`Window height: ${oldHeight} --> ${newHeight}`)
    })
    watch(windowWidth, (newWidth, oldWidth) => {
      logger.debug(`Window width: ${oldWidth} --> ${newWidth}`)
    })

    const windowResizeHandler = () => {
      windowHeight.value = window.innerHeight
      windowWidth.value = window.innerWidth
    }

    const streamIsLive = ref(true)
    const streamCheckInterval = ref(null)
    const streamCheckHandler = async () => {
      console.log(`Checking stream URL: ${streamOptions.src}`)
      const isLive = await checkRTMPStreamURL({ rtmpURL: streamOptions.src })
      console.log(`Stream is ${(isLive ? '' : 'not ')}live.`)
      streamIsLive.value = isLive
    }


    onMounted(() => {
      window.addEventListener('resize', windowResizeHandler)
      if (streamCheckInterval.value) {
        clearInterval(streamCheckInterval.value)
      }
      streamCheckInterval.value = setInterval(streamCheckHandler, 5000)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', windowResizeHandler)
      if (streamCheckInterval.value) {
        clearInterval(streamCheckInterval.value)
      }
    })
    
    // Theme Mgmt
    const currentTheme = ref('light')
    const darkThemeSwitch = ref(true)

    watch(darkThemeSwitch, (val) => {
      if (val) {
        currentTheme.value = 'dark'
      } else {
        currentTheme.value = 'light'
      }
    })

    // Device info
    let ua = window.navigator.userAgent;
    let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    // let webkit = !!ua.match(/WebKit/i);
    // let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    const isIOS = ref(iOS)

    // External Links

    // const visitAuth0 = () => {
    //   window.open('https://auth0.com')
    // }

    // User Authentication
    // const loginBenefitMesssage = ref('Sign up for free to save your tracking numbers!')
    // const { getAccessTokenSilently, loginWithRedirect, logout, user, isAuthenticated } = useAuth0()
    // const logInOrOut = () => {
    //   if (isAuthenticated.value === true) {
    //     // Log out
    //     logout({ returnTo: window.location.origin })
    //   } else {
    //     loginWithRedirect()
    //   }
    // }

    // Maintain Logged In User Message
    // const loggedInUserMessage = ref('')
    // watch(user, (thisUser) => {
    //   if (thisUser?.name) {
    //     loggedInUserMessage.value = `Logged In As: ${thisUser.name}`
    //   } else {
    //     loggedInUserMessage.value = ''
    //   }
    // }, { immediate: true, deep: true })


    // Load User content when auth status changes to true
    // watch(isAuthenticated, async (isAuthd) => {
    //   if (isAuthd) {
    //     // TODO
    //   }
    // })

    return {
      componentTitle,
      projectVersion,
      currentTheme,
      darkThemeSwitch,
      mdAndUp, // size class bool
      windowHeight,
      windowWidth,
      // Stream Data
      streamIsLive,
      isIOS,
      streamOptions
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
    font-family: "ChristmasGift";
    src: url('@/assets/fonts/ChristmasGift-7B4Ll.woff');
}
body {
	-webkit-text-size-adjust: none;
	color: #B2D665;
	font-size: 1em;
	font-family: "ChristmasGift", Ubuntu, "WenQuanYi Micro Hei", Helvetica, "Hiragino Sans GB", "Microsoft JhengHei", "PMingLiU", Arial, sans-serif;
	-webkit-font-smoothing:antialiased;
	text-shadow: 0 0 1px rgba(0,0,0,0.1);
	margin: 0px;
	line-height: 1.6em;
	padding: 0px;
	background-color: #eee;
	overflow-x: hidden;
}
#Frames, .Frame {
	list-style: none;
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	text-align: center;
}
#Frames {
  margin: 2% 0;
	width: 100%;
}
.Frame {
	display: inline-block;
	padding: 20px;
	border-width: 20px;
	border-style: solid;
	border-color: #2F2D2D #434040 #4F4C4C #434040;
	background: #f5f5f5;
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#E5E4DF), to(#CDCDC6));
	background-image: -webkit-linear-gradient(#E5E4DF, #CDCDC6);
	background-image: -moz-linear-gradient(#E5E4DF, #CDCDC6);
	background-image: -o-linear-gradient(#E5E4DF, #CDCDC6);
	background-image: linear-gradient(#E5E4DF, #CDCDC6);
	box-shadow: inset 0 2px 5px rgba(0,0,0,.6),0 5px 2px rgba(0,0,0,.1), 0 10px 20px rgba(0,0,0,.8);
	position: relative;
	overflow: hidden;
}
.Frame::before {
	content: "";
	position: absolute;
	top: -175px;
	right: -20%;
	width: 400px;
	height: 400px;
	transform: rotateZ(-40deg);
	-webkit-transform: rotateZ(-40deg);
	-moz-transform: rotateZ(-40deg);
	-o-transform: rotateZ(-40deg);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(rgba(255,255,255,.4)), to(rgba(255,255,255,0)));
	background-image: -webkit-linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,0));
	background-image: -moz-linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,0));
	background-image: -o-linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,0));
	background-image: linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,0));
}
.Frame img {
	border-width: 2px;
	border-style: solid;
	border-color: #BBBAB4 #C7C7BF #E5E4DF #C7C7BF;
	box-shadow:  0 -1px 1px rgba(0,0,0,.1), 0 1px 1px 1px rgba(255,255,255,.7);
}

.christmas-text {
	font-family: "ChristmasGift";
  text-align: center;
	-webkit-font-smoothing:antialiased;
}

.large-type {
  font-size: 80px;
}
</style>