// From: https://stackoverflow.com/a/57888548/2333933
const fetchTimeout = (url, ms, { signal, ...options } = {}) => {
    const controller = new AbortController();
    const promise = fetch(url, { signal: controller.signal, ...options });
    if (signal) signal.addEventListener("abort", () => controller.abort());
    const timeout = setTimeout(() => controller.abort(), ms);
    return promise.finally(() => clearTimeout(timeout));
  };

const fetchOrLogError = async ({ url, timeoutMS, options }) => {
  try {
    return await fetchTimeout(url, timeoutMS, options)
  } catch (error) {
    if (error.name === "AbortError") {
        console.log(`Timeout trying URL: '${url}': ${error}`)
    } else {
        console.log(`Fetch error trying URL: '${url}': ${error}`)
    }
  }
  return null
}

/**
 * Verify RTMP Stream URL is serving content
 */
export const checkRTMPStreamURL = async ({ rtmpURL }) => {
    const resRaw = await fetchOrLogError({
        url: rtmpURL,
        timeoutMS: 3000,
        options: {
            method: 'GET'
        }
    })

    let status = resRaw?.status

    if (status === 200) {
        // Check content?
        return true
    }
    return false
}